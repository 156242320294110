<template>
	<modal class="NoxModalAdminQuests" name="NoxModalAdminQuests" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addQuestsTask'">Добавление задания квеста</span>
					<span v-else-if="noxType == 'editQuestsTask'">Редактирование задания квеста</span>
					<span v-else-if="noxType == 'deleteQuestsTask'">Удаление задания квеста</span>
					<span v-else-if="noxType == 'addQuestsScript'">Добавление скрипта квеста</span>
					<span v-else-if="noxType == 'editQuestsScript'">Редактирование скрипта квеста</span>
					<span v-else-if="noxType == 'deleteQuestsScript'">Удаление скрипта квеста</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div class="nox_modal_info_quests">
						<div class="nox_modal_info_quests_task" v-if="['addQuestsTask', 'editQuestsTask', 'deleteQuestsTask'].includes(noxType)">
							<div v-if="['addQuestsTask', 'editQuestsTask'].includes(noxType)">
								<div class="nox_modal_info_box">
									<div class="nox_modal_info_box_title">Основные настройки:</div>
									<div class="nox_modal_info_box_content">
										<div class="nox_modal_info_row">
											<div class="nox_modal_info_row_label">Тип задания:</div>
											<div class="nox_modal_info_row_value">
												<v-select class="nox_select style-chooser" v-model.trim="noxQuestsTaskType" placeholder="тип задания" :options="$parent.noxQuestsTasksTypes"></v-select>
												<div v-html="noxAlertQuestsTaskType"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskType && noxQuestsTaskType.code == 2">
											<div class="nox_modal_info_row_label">Скрипт задания:</div>
											<div class="nox_modal_info_row_value">
												<v-select class="nox_select style-chooser" v-model.trim="noxQuestsTaskScriptId" placeholder="скрипт задания" :options="$parent.noxQuestsTasksScripts"></v-select>
												<div v-html="noxAlertQuestsTaskScriptId"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskType && noxQuestsTaskType.code == 2 && noxQuestsTaskScriptId && noxQuestsTaskScriptId.is_x_count">
											<div class="nox_modal_info_row_label">Значение переменной X:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskScriptOptionsXCount" placeholder="значение переменной X" autocomplete="off">
												<div v-html="noxAlertQuestsTaskScriptOptionsXCount"></div>
											</div>
										</div>
										<div class="nox_modal_info_row">
											<div class="nox_modal_info_row_label">Название задания (RU):</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskNameRU" placeholder="название задания (ru)" autocomplete="off">
												<div v-html="noxAlertQuestsTaskNameRU"></div>
											</div>
										</div>
										<div class="nox_modal_info_row">
											<div class="nox_modal_info_row_label">Название задания (EN):</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskNameEN" placeholder="название задания (en)" autocomplete="off">
												<div v-html="noxAlertQuestsTaskNameEN"></div>
											</div>
										</div>
										<div class="nox_modal_info_row">
											<div class="nox_modal_info_row_label">Текст задания (RU):</div>
											<div class="nox_modal_info_row_value">
												<textarea class="nox_textarea small" v-model.trim="noxQuestsTaskTextRU" placeholder="html текст задания (ru)"></textarea>
												<div v-html="noxAlertQuestsTaskTextRU"></div>
											</div>
										</div>
										<div class="nox_modal_info_row">
											<div class="nox_modal_info_row_label">Текст задания (EN):</div>
											<div class="nox_modal_info_row_value">
												<textarea class="nox_textarea small" v-model.trim="noxQuestsTaskTextEN" placeholder="html текст задания (en)"></textarea>
												<div v-html="noxAlertQuestsTaskTextEN"></div>
											</div>
										</div>
										<div class="nox_modal_info_row">
											<div class="nox_modal_info_row_label">Приоритет задания:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskPriority" placeholder="приоритет задания" autocomplete="off">
												<div v-html="noxAlertQuestsTaskPriority"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskType && noxQuestsTaskType.code == 1">
											<div class="nox_modal_info_row_label">Время выполнения:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskDeferral" placeholder="время выполнения (в минутах)" autocomplete="off">
												<div v-html="noxAlertQuestsTaskDeferral"></div>
											</div>
										</div>
										<div class="nox_modal_info_row last">
											<div class="nox_modal_info_row_label">Статус задания:</div>
											<div class="nox_modal_info_row_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(noxQuestsTaskIsActive)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive"></toggle-button>
												<div v-html="noxAlertQuestsTaskIsActive"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="nox_modal_info_box">
									<div class="nox_modal_info_box_title">Настройки вознаграждений:</div>
									<div class="nox_modal_info_box_content">
										<div class="nox_modal_info_row" :class="{ last: !noxQuestsTaskIsRewards }">
											<div class="nox_modal_info_row_label">Вознаграждения:</div>
											<div class="nox_modal_info_row_value">
												<toggle-button class="nox_toggle_button" :value="toggleValue(noxQuestsTaskIsRewards)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRewards"></toggle-button>
												<div v-html="noxAlertQuestsTaskIsRewards"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskIsRewards">
											<div class="nox_modal_info_row_label">... учитывать от даты:</div>
											<div class="nox_modal_info_row_value">
												<div class="nox_datetime">
													<div class="nox_datetime_clear" v-if="noxQuestsTaskRewardsDatetime" @click="noxQuestsTaskRewardsDatetime = '';"><font-awesome-icon :icon="['fas', 'times']" /></div>
													<div class="nox_datetime_block"><datetime type="datetime" format="dd.MM.yyyy, HH:mm:ss" :phrases="{ ok: '[Продолжить]', cancel: '[Закрыть]' }" v-model="noxQuestsTaskRewardsDatetime" placeholder="дата начала учета" auto></datetime></div>
												</div>
												<div v-html="noxAlertQuestsTaskRewardsDatetime"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskIsRewards">
											<div class="nox_modal_info_row_label">... на основной баланс:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskRewardsValue1" placeholder="сумма средств на основной баланс" autocomplete="off">
												<div v-html="noxAlertQuestsTaskRewardsValue1"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskIsRewards">
											<div class="nox_modal_info_row_label">... на бонусный баланс:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskRewardsValue2" placeholder="сумма средств на бонусный баланс" autocomplete="off">
												<div v-html="noxAlertQuestsTaskRewardsValue2"></div>
											</div>
										</div>
										<div class="nox_modal_info_row" v-if="noxQuestsTaskIsRewards">
											<div class="nox_modal_info_row_label">... на баланс перк бонуса:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskRewardsValue3" placeholder="количество перков" autocomplete="off">
												<div v-html="noxAlertQuestsTaskRewardsValue3"></div>
											</div>
										</div>
										<div class="nox_modal_info_row last" v-if="noxQuestsTaskIsRewards">
											<div class="nox_modal_info_row_label">... на абонентскую плату:</div>
											<div class="nox_modal_info_row_value">
												<input type="text" class="nox_input" v-model.trim="noxQuestsTaskRewardsValue4" placeholder="количество дней абонентской платы аккаунта" autocomplete="off">
												<div v-html="noxAlertQuestsTaskRewardsValue4"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="nox_modal_info_box" v-if="noxQuestsTaskType && noxQuestsTaskType.code == 1">
									<div class="nox_modal_info_box_title">Настройки кнопок:</div>
									<div class="nox_modal_info_box_content">
										<div class="nox_modal_info_box_content_block">
											<div class="nox_modal_info_quests_task_buttons">
												<div class="nox_modal_info_quests_task_button" v-for="(value, index) in noxQuestsTaskButtons" :key="index">
													<div class="nox_modal_info_quests_task_button_title" :class="[(value.type == 2 ? 'blue' : 'orange')]">{{ (index + 1) }}. {{ (value.type == 2 ? 'Кнопка - меню' : 'Обычная кнопка') }}</div>
													<div class="nox_modal_info_quests_task_button_delete" v-if="noxQuestsTaskButtons.length > 1"><button type="button" class="nox_button common red" @click="deleteQuestsTaskButton(index)"><font-awesome-icon :icon="['fas', 'times']" /></button></div>
													<div class="nox_modal_info_quests_task_button_content" v-if="value.type == 1">
														<div class="nox_modal_info_quests_task_button_content_settings">
															<div class="nox_modal_info_row">
																<div class="nox_modal_info_row_label">Иконка кнопки:</div>
																<div class="nox_modal_info_row_value">
																	<v-select class="nox_select style-chooser" v-model.trim="value.icon" placeholder="html код иконки кнопки" :options="noxQuestsTaskButtonsIcons"></v-select>
																	<div v-html="value.alerts.alert_icon" v-if="value.alerts && value.alerts.alert_icon"></div>
																</div>
															</div>
															<div class="nox_modal_info_row">
																<div class="nox_modal_info_row_label">Название кнопки (RU):</div>
																<div class="nox_modal_info_row_value">
																	<input type="text" class="nox_input" v-model.trim="value.name_ru" placeholder="название кнопки (ru)" autocomplete="off">
																	<div v-html="value.alerts.alert_name_ru" v-if="value.alerts && value.alerts.alert_name_ru"></div>
																</div>
															</div>
															<div class="nox_modal_info_row">
																<div class="nox_modal_info_row_label">Название кнопки (EN):</div>
																<div class="nox_modal_info_row_value">
																	<input type="text" class="nox_input" v-model.trim="value.name_en" placeholder="название кнопки (en)" autocomplete="off">
																	<div v-html="value.alerts.alert_name_en" v-if="value.alerts && value.alerts.alert_name_en"></div>
																</div>
															</div>
															<div class="nox_modal_info_row last">
																<div class="nox_modal_info_row_label">Ссылка кнопки:</div>
																<div class="nox_modal_info_row_value">
																	<input type="text" class="nox_input" v-model.trim="value.url" placeholder="ссылка кнопки" autocomplete="off">
																	<div v-html="value.alerts.alert_url" v-if="value.alerts && value.alerts.alert_url"></div>
																</div>
															</div>
														</div>
													</div>
													<div class="nox_modal_info_quests_task_button_content" v-else-if="value.type == 2">
														<div class="nox_modal_info_quests_task_button_content_settings">
															<div class="nox_modal_info_row">
																<div class="nox_modal_info_row_label">Иконка кнопки:</div>
																<div class="nox_modal_info_row_value">
																	<v-select class="nox_select style-chooser" v-model.trim="value.icon" placeholder="html код иконки кнопки" :options="noxQuestsTaskButtonsIcons"></v-select>
																	<div v-html="value.alerts.alert_icon" v-if="value.alerts && value.alerts.alert_icon"></div>
																</div>
															</div>
															<div class="nox_modal_info_row">
																<div class="nox_modal_info_row_label">Название кнопки (RU):</div>
																<div class="nox_modal_info_row_value">
																	<input type="text" class="nox_input" v-model.trim="value.name_ru" placeholder="название кнопки (ru)" autocomplete="off">
																	<div v-html="value.alerts.alert_name_ru" v-if="value.alerts && value.alerts.alert_name_ru"></div>
																</div>
															</div>
															<div class="nox_modal_info_row last">
																<div class="nox_modal_info_row_label">Название кнопки (EN):</div>
																<div class="nox_modal_info_row_value">
																	<input type="text" class="nox_input" v-model.trim="value.name_en" placeholder="название кнопки (en)" autocomplete="off">
																	<div v-html="value.alerts.alert_name_en" v-if="value.alerts && value.alerts.alert_name_en"></div>
																</div>
															</div>
														</div>
														<div class="nox_modal_info_quests_task_button_content_items">
															<div class="nox_modal_info_quests_task_button_content_item" v-for="(value2, index2) in value.items" :key="index2">
																<div class="nox_modal_info_quests_task_button_content_item_title blue">Пункт кнопки - меню #{{ (index2 + 1) }}.</div>
																<div class="nox_modal_info_quests_task_button_content_item_delete" v-if="value.items.length > 1"><button type="button" class="nox_button common red" @click="deleteQuestsTaskButtonItem(index, index2)"><font-awesome-icon :icon="['fas', 'times']" /></button></div>
																<div class="nox_modal_info_quests_task_button_content_item_settings">
																	<div class="nox_modal_info_row">
																		<div class="nox_modal_info_row_label">Название пункта (RU):</div>
																		<div class="nox_modal_info_row_value">
																			<input type="text" class="nox_input" v-model.trim="value2.name_ru" placeholder="название пункта кнопки - меню (ru)" autocomplete="off">
																			<div v-html="value2.alerts.alert_name_ru" v-if="value2.alerts && value2.alerts.alert_name_ru"></div>
																		</div>
																	</div>
																	<div class="nox_modal_info_row">
																		<div class="nox_modal_info_row_label">Название пункта (EN):</div>
																		<div class="nox_modal_info_row_value">
																			<input type="text" class="nox_input" v-model.trim="value2.name_en" placeholder="название пункта кнопки - меню (en)" autocomplete="off">
																			<div v-html="value2.alerts.alert_name_en" v-if="value2.alerts && value2.alerts.alert_name_en"></div>
																		</div>
																	</div>
																	<div class="nox_modal_info_row last">
																		<div class="nox_modal_info_row_label">Ссылка пункта:</div>
																		<div class="nox_modal_info_row_value">
																			<input type="text" class="nox_input" v-model.trim="value2.url" placeholder="ссылка пункта кнопки - меню" autocomplete="off">
																			<div v-html="value2.alerts.alert_url" v-if="value2.alerts && value2.alerts.alert_url"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="nox_modal_info_quests_task_button_buttons" v-if="value.type == 2">
														<button type="button" class="nox_button common blue" @click="addQuestsTaskButtonItem(0, index)">Добавить пункт кнопки - меню</button>
													</div>
												</div>
											</div>
										</div>
										<div class="nox_modal_info_box_content_buttons">
											<button type="button" class="nox_button common blue" @click="addQuestsTaskButton(2)">Добавить кнопку - меню</button>
											<button type="button" class="nox_button common orange" @click="addQuestsTaskButton(1)">Добавить обычную кнопку</button>
										</div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'deleteQuestsTask'">Вы действительно хотите удалить задание квеста?</div>
						</div>
						<div class="nox_modal_info_quests_script" v-else-if="['addQuestsScript', 'editQuestsScript', 'deleteQuestsScript'].includes(noxType)">
							<div v-if="['addQuestsScript', 'editQuestsScript'].includes(noxType)">
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Название скрипта:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxQuestsScriptName" placeholder="название скрипта" autocomplete="off">
										<div v-html="noxAlertQuestsScriptName"></div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'deleteQuestsScript'">Вы действительно хотите удалить скрипт квеста?</div>
						</div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="['addQuestsTask', 'addQuestsScript'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['editQuestsTask', 'editQuestsScript'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="['deleteQuestsTask', 'deleteQuestsScript'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertQuestsTaskType: '',
			noxAlertQuestsTaskNameRU: '',
			noxAlertQuestsTaskNameEN: '',
			noxAlertQuestsTaskTextRU: '',
			noxAlertQuestsTaskTextEN: '',
			noxAlertQuestsTaskPriority: '',
			noxAlertQuestsTaskDeferral: '',
			noxAlertQuestsTaskScriptId: '',
			noxAlertQuestsTaskScriptOptionsXCount: '',
			noxAlertQuestsTaskRewardsDatetime: '',
			noxAlertQuestsTaskRewardsValue1: '',
			noxAlertQuestsTaskRewardsValue2: '',
			noxAlertQuestsTaskRewardsValue3: '',
			noxAlertQuestsTaskRewardsValue4: '',
			noxAlertQuestsTaskIsRewards: '',
			noxAlertQuestsTaskIsActive: '',
			noxAlertQuestsScriptName: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxQuestsTaskType: '',
			noxQuestsTaskNameRU: '',
			noxQuestsTaskNameEN: '',
			noxQuestsTaskTextRU: '',
			noxQuestsTaskTextEN: '',
			noxQuestsTaskPriority: '',
			noxQuestsTaskDeferral: '',
			noxQuestsTaskScriptId: '',
			noxQuestsTaskScriptOptions: {},
			noxQuestsTaskScriptOptionsXCount: '',
			noxQuestsTaskRewards: {},
			noxQuestsTaskRewardsDatetime: '',
			noxQuestsTaskRewardsValue1: '',
			noxQuestsTaskRewardsValue2: '',
			noxQuestsTaskRewardsValue3: '',
			noxQuestsTaskRewardsValue4: '',
			noxQuestsTaskButtons: [],
			noxQuestsTaskButtonsIcons: [],
			noxQuestsTaskIsRewards: 0,
			noxQuestsTaskIsActive: 0,
			noxQuestsScriptName: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxQuestsTaskType = '';
				this.noxQuestsTaskNameRU = '';
				this.noxQuestsTaskNameEN = '';
				this.noxQuestsTaskTextRU = '';
				this.noxQuestsTaskTextEN = '';
				this.noxQuestsTaskPriority = '';
				this.noxQuestsTaskDeferral = '';
				this.noxQuestsTaskScriptId = '';
				this.noxQuestsTaskScriptOptions = {};
				this.noxQuestsTaskScriptOptionsXCount = '';
				this.noxQuestsTaskRewards = {};
				this.noxQuestsTaskRewardsDatetime = '';
				this.noxQuestsTaskRewardsValue1 = '';
				this.noxQuestsTaskRewardsValue2 = '';
				this.noxQuestsTaskRewardsValue3 = '';
				this.noxQuestsTaskRewardsValue4 = '';
				this.noxQuestsTaskButtons = [];
				this.noxQuestsTaskButtonsIcons = ['fas fa-download', 'fas fa-eye', 'fas fa-file-alt', 'fas fa-file-pdf', 'fas fa-play'];
				this.noxQuestsTaskIsRewards = 0;
				this.noxQuestsTaskIsActive = 0;
				this.noxQuestsScriptName = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (['addQuestsTask', 'editQuestsTask'].includes(this.noxType)) {
					if (this.noxType == 'addQuestsTask') {
						this.noxQuestsTaskButtons = [this.getQuestsTaskButton(1)];
					}
					else if (this.noxType == 'editQuestsTask') {
						this.noxQuestsTaskType = this.$parent.noxQuestsTasks[this.noxIndex].type;
						this.noxQuestsTaskType = this.noxQuestsTaskType ? this.$parent.noxQuestsTasksTypes[this.noxQuestsTaskType - 1] : '';
						this.noxQuestsTaskNameRU = this.$parent.noxQuestsTasks[this.noxIndex].name_ru;
						this.noxQuestsTaskNameEN = this.$parent.noxQuestsTasks[this.noxIndex].name_en;
						this.noxQuestsTaskTextRU = this.$parent.noxQuestsTasks[this.noxIndex].text_ru;
						this.noxQuestsTaskTextEN = this.$parent.noxQuestsTasks[this.noxIndex].text_en;
						this.noxQuestsTaskPriority = this.$parent.noxQuestsTasks[this.noxIndex].priority;
						this.noxQuestsTaskDeferral = this.$parent.noxQuestsTasks[this.noxIndex].deferral;
						this.noxQuestsTaskScriptId = this.$parent.noxQuestsTasks[this.noxIndex].script_id;
						this.noxQuestsTaskScriptId = this.noxQuestsTaskScriptId ? this.$parent.noxQuestsTasksScripts[this.noxQuestsTaskScriptId - 1] : '';
						this.noxQuestsTaskScriptOptions = this.$parent.noxQuestsTasks[this.noxIndex].script_options;
						this.noxQuestsTaskScriptOptionsXCount = this.noxQuestsTaskScriptOptions.x_count ? this.noxQuestsTaskScriptOptions.x_count : '';
						this.noxQuestsTaskRewards = this.$parent.noxQuestsTasks[this.noxIndex].rewards;
						this.noxQuestsTaskRewardsDatetime = this.noxQuestsTaskRewards.datetime ? this.noxQuestsTaskRewards.datetime : '';
						this.noxQuestsTaskRewardsValue1 = this.noxQuestsTaskRewards.value_1 ? this.noxQuestsTaskRewards.value_1 : '';
						this.noxQuestsTaskRewardsValue2 = this.noxQuestsTaskRewards.value_2 ? this.noxQuestsTaskRewards.value_2 : '';
						this.noxQuestsTaskRewardsValue3 = this.noxQuestsTaskRewards.value_3 ? this.noxQuestsTaskRewards.value_3 : '';
						this.noxQuestsTaskRewardsValue4 = this.noxQuestsTaskRewards.value_4 ? this.noxQuestsTaskRewards.value_4 : '';
						this.noxQuestsTaskButtons = this.$parent.noxQuestsTasks[this.noxIndex].buttons;
						this.noxQuestsTaskButtons = this.noxQuestsTaskButtons.length ? this.noxQuestsTaskButtons : [this.getQuestsTaskButton(1)];
						this.noxQuestsTaskIsRewards = this.$parent.noxQuestsTasks[this.noxIndex].is_rewards;
						this.noxQuestsTaskIsActive = this.$parent.noxQuestsTasks[this.noxIndex].is_active;
					}
					this.initQuestsTaskButtonsAlerts();
				}
				else if (this.noxType == 'editQuestsScript') {
					this.noxQuestsScriptName = this.$parent.noxQuestsScripts[this.noxIndex].name;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertQuestsTaskType = '';
				this.noxAlertQuestsTaskNameRU = '';
				this.noxAlertQuestsTaskNameEN = '';
				this.noxAlertQuestsTaskTextRU = '';
				this.noxAlertQuestsTaskTextEN = '';
				this.noxAlertQuestsTaskPriority = '';
				this.noxAlertQuestsTaskDeferral = '';
				this.noxAlertQuestsTaskScriptId = '';
				this.noxAlertQuestsTaskScriptOptionsXCount = '';
				this.noxAlertQuestsTaskRewardsDatetime = '';
				this.noxAlertQuestsTaskRewardsValue1 = '';
				this.noxAlertQuestsTaskRewardsValue2 = '';
				this.noxAlertQuestsTaskRewardsValue3 = '';
				this.noxAlertQuestsTaskRewardsValue4 = '';
				this.noxAlertQuestsTaskIsRewards = '';
				this.noxAlertQuestsTaskIsActive = '';
				this.noxAlertQuestsScriptName = '';
			},
			initQuestsTaskButtonsAlerts: function() {
				if (this.noxQuestsTaskButtons.length) {
					for (var i in this.noxQuestsTaskButtons) {
						this.$set(this.noxQuestsTaskButtons[i], 'alerts', {});
						if (this.noxQuestsTaskButtons[i].type == 2) {
							for (var j in this.noxQuestsTaskButtons[i].items) {
								this.$set(this.noxQuestsTaskButtons[i].items[j], 'alerts', {});
							}
						}
					}
				}
			},
			addQuestsTaskButton: function(type) {
				this.noxQuestsTaskButtons.push(this.getQuestsTaskButton(type));
			},
			addQuestsTaskButtonItem: function(type, index) {
				this.noxQuestsTaskButtons[index].items.push(this.getQuestsTaskButton(type));
			},
			getError1: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id задания.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id задания.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо выбрать тип задания.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо выбрать верный тип задания.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо выбрать скрипт задания.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо выбрать верный скрипт задания.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать параметры скрипта задания.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верные параметры скрипта задания.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать значение переменной X.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верное значение переменной X.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать название задания (ru).'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верное название задания (ru).'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать название задания (en).'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верное название задания (en).'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать HTML текст задания (ru).'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верный HTML текст задания (ru).'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать HTML текст задания (en).'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать верный HTML текст задания (en).'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать приоритет задания.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать верный приоритет задания.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать время выполнения задания.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать верное время выполнения задания.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать статус задания.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать верный статус задания.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо указать настройки кнопок задания.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо указать верные настройки кнопок задания.'; }
				else if (i == 27) { this.noxTemp = 'Выбранный скрипт задания не работает. Укажите другой.'; }
				else if (i == 28) { this.noxTemp = 'Такой приоритет задания уже есть в системе.'; }
				else if (i == 29) { this.noxTemp = 'Id задания не найден в системе.'; }
				else if (i == 30) { this.noxTemp = 'Id скрипта задания не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать статус вознаграждения задания.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный статус вознаграждения задания.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать параметры вознаграждения задания.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верные параметры вознаграждения задания.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать дату начала учета вознаграждения задания.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верную дату начала учета вознаграждения задания.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать сумму средств для начисления на основной баланс.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верную сумму средств для начисления на основной баланс.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать сумму средств для начисления на бонусный баланс.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верную сумму средств для начисления на бонусный баланс.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать количество перков.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верное количество перков.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать количество дней абонентской платы аккаунта.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верное количество дней абонентской платы аккаунта.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError3: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо выбрать HTML код иконки обычной кнопки.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо выбрать верный HTML код иконки обычной кнопки.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать название обычной кнопки (ru).'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верное название обычной кнопки (ru).'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать название обычной кнопки (en).'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное название обычной кнопки (en).'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать ссылку обычной кнопки.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верную ссылку обычной кнопки.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError4: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо выбрать HTML код иконки кнопки - меню.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо выбрать верный HTML код иконки кнопки - меню.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать название кнопки - меню (ru).'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верное название кнопки - меню (ru).'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать название кнопки - меню (en).'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное название кнопки - меню (en).'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать название пункта кнопки - меню (ru).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верное название пункта кнопки - меню (ru).'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать название пункта кнопки - меню (en).'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верное название пункта кнопки - меню (en).'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать ссылку пункта кнопки - меню.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верную ссылку пункта кнопки - меню.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError5: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id скрипта.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id скрипта.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать название скрипта.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верное название скрипта.'; }
				else if (i == 5) { this.noxTemp = 'Id скрипта не найден в системе.'; }
				else if (i == 6) { this.noxTemp = 'Данный скрипт используется. Удаление невозможно.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getQuestsTaskButton: function(type) {

				var button0 = { name_ru: '', name_en: '', url: '', alerts: {} };
				var button1 = { type: 1, icon: '', name_ru: '', name_en: '', url: '', alerts: {} };
				var button2 = { type: 2, icon: '', name_ru: '', name_en: '', items: [button0], alerts: {} };

				return (type == 1 ? button1 : (type == 2 ? button2 : button0));
			},
			deleteQuestsTaskButton: function(index) {
				this.noxQuestsTaskButtons.splice(index, 1);
			},
			deleteQuestsTaskButtonItem: function(index, index2) {
				this.noxQuestsTaskButtons[index].items.splice(index2, 1);
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addQuestsTask', 'editQuestsTask'].includes(_this.noxType)) {
						if (!_this.noxQuestsTaskType) {
							_this.noxAlertQuestsTaskType = _this.getError1(3);
						}
						else if (_this.noxQuestsTaskType.code == undefined) {
							_this.noxAlertQuestsTaskType = _this.getError1(4);
						}
						_this.noxQuestsTaskScriptOptions = {};
						if (_this.noxQuestsTaskType.code == 2) {
							if (!_this.noxQuestsTaskScriptId) {
								_this.noxAlertQuestsTaskScriptId = _this.getError1(5);
							}
							else if (_this.noxQuestsTaskScriptId.code == undefined) {
								_this.noxAlertQuestsTaskScriptId = _this.getError1(6);
							}
							if (_this.noxQuestsTaskScriptId.is_x_count) {
								if (!_this.noxQuestsTaskScriptOptionsXCount) {
									_this.noxAlertQuestsTaskScriptOptionsXCount = _this.getError1(9);
								}
								else if (!_this.$store.state.noxRegex_d.test(_this.noxQuestsTaskScriptOptionsXCount)) {
									_this.noxAlertQuestsTaskScriptOptionsXCount = _this.getError1(10);
								}
								else {
									_this.noxQuestsTaskScriptOptions.x_count = Number(_this.noxQuestsTaskScriptOptionsXCount);
								}
							}
						}
						if (!_this.noxQuestsTaskNameRU) {
							_this.noxAlertQuestsTaskNameRU = _this.getError1(11);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskNameRU)) {
							_this.noxAlertQuestsTaskNameRU = _this.getError1(12);
						}
						if (!_this.noxQuestsTaskNameEN) {
							_this.noxAlertQuestsTaskNameEN = _this.getError1(13);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskNameEN)) {
							_this.noxAlertQuestsTaskNameEN = _this.getError1(14);
						}
						if (!_this.noxQuestsTaskTextRU) {
							_this.noxAlertQuestsTaskTextRU = _this.getError1(15);
						}
						if (!_this.noxQuestsTaskTextEN) {
							_this.noxAlertQuestsTaskTextEN = _this.getError1(17);
						}
						if (!_this.noxQuestsTaskPriority) {
							_this.noxAlertQuestsTaskPriority = _this.getError1(19);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxQuestsTaskPriority) || _this.noxQuestsTaskPriority < 1) {
							_this.noxAlertQuestsTaskPriority = _this.getError1(20);
						}
						if (_this.noxQuestsTaskType.code == 1) {
							if (_this.noxQuestsTaskDeferral && (!_this.$store.state.noxRegex_d.test(_this.noxQuestsTaskDeferral) || _this.noxQuestsTaskDeferral < 0)) {
								_this.noxAlertQuestsTaskDeferral = _this.getError1(22);
							}
						}
						_this.noxQuestsTaskRewards = {};
						if (_this.noxQuestsTaskIsRewards) {
							if (_this.noxQuestsTaskRewardsDatetime && (new Date(_this.noxQuestsTaskRewardsDatetime)) == 'Invalid Date') {
								_this.noxAlertQuestsTaskRewardsDatetime = _this.getError2(6);
							}
							if (_this.noxQuestsTaskRewardsValue1 && (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxQuestsTaskRewardsValue1) || _this.noxQuestsTaskRewardsValue1 < 0)) {
								_this.noxAlertQuestsTaskRewardsValue1 = _this.getError2(8);
							}
							if (_this.noxQuestsTaskRewardsValue2 && (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxQuestsTaskRewardsValue2) || _this.noxQuestsTaskRewardsValue2 < 0)) {
								_this.noxAlertQuestsTaskRewardsValue2 = _this.getError2(10);
							}
							if (_this.noxQuestsTaskRewardsValue3 && (!_this.$store.state.noxRegex_d.test(_this.noxQuestsTaskRewardsValue3) || _this.noxQuestsTaskRewardsValue3 < 1)) {
								_this.noxAlertQuestsTaskRewardsValue3 = _this.getError2(12);
							}
							if (_this.noxQuestsTaskRewardsValue4 && (!_this.$store.state.noxRegex_d.test(_this.noxQuestsTaskRewardsValue4) || _this.noxQuestsTaskRewardsValue4 < 1)) {
								_this.noxAlertQuestsTaskRewardsValue4 = _this.getError2(14);
							}
							_this.noxQuestsTaskRewards.datetime = _this.noxQuestsTaskRewardsDatetime;
							_this.noxQuestsTaskRewards.value_1 = Number(_this.noxQuestsTaskRewardsValue1);
							_this.noxQuestsTaskRewards.value_2 = Number(_this.noxQuestsTaskRewardsValue2);
							_this.noxQuestsTaskRewards.value_3 = Number(_this.noxQuestsTaskRewardsValue3);
							_this.noxQuestsTaskRewards.value_4 = Number(_this.noxQuestsTaskRewardsValue4);
						}
						_this.initQuestsTaskButtonsAlerts();
						if (_this.noxQuestsTaskType.code == 1) {
							if (_this.noxQuestsTaskButtons.length) {
								for (var i in _this.noxQuestsTaskButtons) {
									if (_this.noxQuestsTaskButtons[i].type == 1) {
										if (!_this.noxQuestsTaskButtons[i].icon) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_icon', _this.getError3(1));
										}
										else if (!_this.$store.state.noxRegexENLettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].icon)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_icon', _this.getError3(2));
										}
										if (!_this.noxQuestsTaskButtons[i].name_ru) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_ru', _this.getError3(3));
										}
										else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].name_ru)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_ru', _this.getError3(4));
										}
										if (!_this.noxQuestsTaskButtons[i].name_en) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_en', _this.getError3(5));
										}
										else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].name_en)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_en', _this.getError3(6));
										}
										if (!_this.noxQuestsTaskButtons[i].url) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_url', _this.getError3(7));
										}
										else if (!_this.$store.state.noxRegexAnyLink.test(_this.noxQuestsTaskButtons[i].url)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_url', _this.getError3(8));
										}
									}
									else if (_this.noxQuestsTaskButtons[i].type == 2) {
										if (!_this.noxQuestsTaskButtons[i].icon) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_icon', _this.getError4(1));
										}
										else if (!_this.$store.state.noxRegexENLettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].icon)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_icon', _this.getError4(2));
										}
										if (!_this.noxQuestsTaskButtons[i].name_ru) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_ru', _this.getError4(3));
										}
										else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].name_ru)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_ru', _this.getError4(4));
										}
										if (!_this.noxQuestsTaskButtons[i].name_en) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_en', _this.getError4(5));
										}
										else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].name_en)) {
											_this.$set(_this.noxQuestsTaskButtons[i].alerts, 'alert_name_en', _this.getError4(6));
										}
										if (_this.noxQuestsTaskButtons[i].items.length) {
											for (var j in _this.noxQuestsTaskButtons[i].items) {
												if (!_this.noxQuestsTaskButtons[i].items[j].name_ru) {
													_this.$set(_this.noxQuestsTaskButtons[i].items[j].alerts, 'alert_name_ru', _this.getError4(7));
												}
												else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].items[j].name_ru)) {
													_this.$set(_this.noxQuestsTaskButtons[i].items[j].alerts, 'alert_name_ru', _this.getError4(8));
												}
												if (!_this.noxQuestsTaskButtons[i].items[j].name_en) {
													_this.$set(_this.noxQuestsTaskButtons[i].items[j].alerts, 'alert_name_en', _this.getError4(9));
												}
												else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsTaskButtons[i].items[j].name_en)) {
													_this.$set(_this.noxQuestsTaskButtons[i].items[j].alerts, 'alert_name_en', _this.getError4(10));
												}
												if (!_this.noxQuestsTaskButtons[i].items[j].url) {
													_this.$set(_this.noxQuestsTaskButtons[i].items[j].alerts, 'alert_url', _this.getError4(11));
												}
												else if (!_this.$store.state.noxRegexAnyLink.test(_this.noxQuestsTaskButtons[i].items[j].url)) {
													_this.$set(_this.noxQuestsTaskButtons[i].items[j].alerts, 'alert_url', _this.getError4(12));
												}
											}
										}
									}
								}
							}
						}
						if (!_this.noxIsError) {
							if (_this.noxQuestsTaskButtons.length) {
								for (var m in _this.noxQuestsTaskButtons) {
									delete _this.noxQuestsTaskButtons[m].alerts;
									if (_this.noxQuestsTaskButtons[m].type == 2) {
										for (var n in _this.noxQuestsTaskButtons[m].items) {
											delete _this.noxQuestsTaskButtons[m].items[n].alerts;
										}
									}
								}
							}
							config.url = '/v2/admin/account/quests/tasks';
							config.data = { id: _this.noxId, type: _this.noxQuestsTaskType.code, name_ru: _this.noxQuestsTaskNameRU, name_en: _this.noxQuestsTaskNameEN, text_ru: _this.noxQuestsTaskTextRU, text_en: _this.noxQuestsTaskTextEN, priority: _this.noxQuestsTaskPriority, deferral: ((_this.noxQuestsTaskType.code == 1 && _this.noxQuestsTaskDeferral) ? _this.noxQuestsTaskDeferral : 0), script_id: (_this.noxQuestsTaskType.code == 2 ? _this.noxQuestsTaskScriptId.code : 0), script_options: JSON.stringify(_this.noxQuestsTaskScriptOptions), rewards: JSON.stringify(_this.noxQuestsTaskRewards), buttons: (_this.noxQuestsTaskType.code == 1 ? JSON.stringify(_this.noxQuestsTaskButtons) : ''), is_rewards: _this.noxQuestsTaskIsRewards, is_active: _this.noxQuestsTaskIsActive };
							config.method = _this.noxType == 'addQuestsTask' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteQuestsTask') {
						config.url = '/v2/admin/account/quests/tasks';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}
					else if (['addQuestsScript', 'editQuestsScript'].includes(_this.noxType)) {
						if (!_this.noxQuestsScriptName) {
							_this.noxAlertQuestsScriptName = _this.getError5(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxQuestsScriptName)) {
							_this.noxAlertQuestsScriptName = _this.getError5(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/quests/scripts';
							config.data = { id: _this.noxId, name: _this.noxQuestsScriptName };
							config.method = _this.noxType == 'addQuestsScript' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteQuestsScript') {
						config.url = '/v2/admin/account/quests/scripts';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addQuestsTask') {
									_this.$parent.noxQuestsTasks.push(data.data);
								}
								else if (_this.noxType == 'editQuestsTask') {
									_this.$parent.noxQuestsTasks.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteQuestsTask') {
									_this.$parent.noxQuestsTasks.splice(_this.noxIndex, 1);
								}
								else if (_this.noxType == 'addQuestsScript') {
									_this.$parent.noxQuestsScripts.push(data.data);
									_this.$parent.initQuestsTasksScripts();
								}
								else if (_this.noxType == 'editQuestsScript') {
									_this.$parent.noxQuestsScripts.splice(_this.noxIndex, 1, data.data);
									_this.$parent.initQuestsTasksScripts();
								}
								else if (_this.noxType == 'deleteQuestsScript') {
									_this.$parent.noxQuestsScripts.splice(_this.noxIndex, 1);
									_this.$parent.initQuestsTasksScripts();
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addQuestsTask', 'editQuestsTask', 'deleteQuestsTask'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError1(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError1(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertQuestsTaskType = _this.getError1(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertQuestsTaskType = _this.getError1(4); }
										else if (data.response.data.error == 'SCRIPT_ID_IS_EMPTY') { _this.noxAlertQuestsTaskScriptId = _this.getError1(5); }
										else if (data.response.data.error == 'SCRIPT_ID_NOT_VALID') { _this.noxAlertQuestsTaskScriptId = _this.getError1(6); }
										else if (data.response.data.error == 'SCRIPT_OPTIONS_IS_EMPTY') { _this.noxAlert = _this.getError1(7); }
										else if (data.response.data.error == 'SCRIPT_OPTIONS_NOT_VALID') { _this.noxAlert = _this.getError1(8); }
										else if (data.response.data.error == 'SCRIPT_OPTIONS_X_COUNT_IS_EMPTY') { _this.noxAlertQuestsTaskScriptOptionsXCount = _this.getError1(9); }
										else if (data.response.data.error == 'SCRIPT_OPTIONS_X_COUNT_NOT_VALID') { _this.noxAlertQuestsTaskScriptOptionsXCount = _this.getError1(10); }
										else if (data.response.data.error == 'NAME_RU_IS_EMPTY') { _this.noxAlertQuestsTaskNameRU = _this.getError1(11); }
										else if (data.response.data.error == 'NAME_RU_NOT_VALID') { _this.noxAlertQuestsTaskNameRU = _this.getError1(12); }
										else if (data.response.data.error == 'NAME_EN_IS_EMPTY') { _this.noxAlertQuestsTaskNameEN = _this.getError1(13); }
										else if (data.response.data.error == 'NAME_EN_NOT_VALID') { _this.noxAlertQuestsTaskNameEN = _this.getError1(14); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.noxAlertQuestsTaskTextRU = _this.getError1(15); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.noxAlertQuestsTaskTextRU = _this.getError1(16); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.noxAlertQuestsTaskTextEN = _this.getError1(17); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.noxAlertQuestsTaskTextEN = _this.getError1(18); }
										else if (data.response.data.error == 'PRIORITY_IS_EMPTY') { _this.noxAlertQuestsTaskPriority = _this.getError1(19); }
										else if (data.response.data.error == 'PRIORITY_NOT_VALID') { _this.noxAlertQuestsTaskPriority = _this.getError1(20); }
										else if (data.response.data.error == 'DEFERRAL_IS_EMPTY') { _this.noxAlertQuestsTaskDeferral = _this.getError1(21); }
										else if (data.response.data.error == 'DEFERRAL_NOT_VALID') { _this.noxAlertQuestsTaskDeferral = _this.getError1(22); }
										else if (data.response.data.error == 'IS_ACTIVE_IS_EMPTY') { _this.noxAlertQuestsTaskIsActive = _this.getError1(23); }
										else if (data.response.data.error == 'IS_ACTIVE_NOT_VALID') { _this.noxAlertQuestsTaskIsActive = _this.getError1(24); }
										else if (data.response.data.error == 'BUTTONS_IS_EMPTY') { _this.noxAlert = _this.getError1(25); }
										else if (data.response.data.error == 'BUTTONS_NOT_VALID') { _this.noxAlert = _this.getError1(26); }
										else if (data.response.data.error == 'IS_REWARDS_IS_EMPTY') { _this.noxAlertQuestsTaskIsRewards = _this.getError2(1); }
										else if (data.response.data.error == 'IS_REWARDS_NOT_VALID') { _this.noxAlertQuestsTaskIsRewards = _this.getError2(2); }
										else if (data.response.data.error == 'REWARDS_IS_EMPTY') { _this.noxAlert = _this.getError2(3); }
										else if (data.response.data.error == 'REWARDS_NOT_VALID') { _this.noxAlert = _this.getError2(4); }
										else if (data.response.data.error == 'REWARDS_DATETIME_IS_EMPTY') { _this.noxAlertQuestsTaskRewardsDatetime = _this.getError2(5); }
										else if (data.response.data.error == 'REWARDS_DATETIME_NOT_VALID') { _this.noxAlertQuestsTaskRewardsDatetime = _this.getError2(6); }
										else if (data.response.data.error == 'REWARDS_VALUE_1_IS_EMPTY') { _this.noxAlertQuestsTaskRewardsValue1 = _this.getError2(7); }
										else if (data.response.data.error == 'REWARDS_VALUE_1_NOT_VALID') { _this.noxAlertQuestsTaskRewardsValue1 = _this.getError2(8); }
										else if (data.response.data.error == 'REWARDS_VALUE_2_IS_EMPTY') { _this.noxAlertQuestsTaskRewardsValue2 = _this.getError2(9); }
										else if (data.response.data.error == 'REWARDS_VALUE_2_NOT_VALID') { _this.noxAlertQuestsTaskRewardsValue2 = _this.getError2(10); }
										else if (data.response.data.error == 'REWARDS_VALUE_3_IS_EMPTY') { _this.noxAlertQuestsTaskRewardsValue3 = _this.getError2(11); }
										else if (data.response.data.error == 'REWARDS_VALUE_3_NOT_VALID') { _this.noxAlertQuestsTaskRewardsValue3 = _this.getError2(12); }
										else if (data.response.data.error == 'REWARDS_VALUE_4_IS_EMPTY') { _this.noxAlertQuestsTaskRewardsValue4 = _this.getError2(13); }
										else if (data.response.data.error == 'REWARDS_VALUE_4_NOT_VALID') { _this.noxAlertQuestsTaskRewardsValue4 = _this.getError2(14); }
									}
									else if (['addQuestsScript', 'editQuestsScript', 'deleteQuestsScript'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError5(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError5(2); }
										else if (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertQuestsScriptName = _this.getError5(3); }
										else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertQuestsScriptName = _this.getError5(4); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addQuestsTask', 'editQuestsTask', 'deleteQuestsTask'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError1(29); }
										else if (data.response.data.error == 'SCRIPT_ID_NOT_FOUND') { _this.noxAlertQuestsTaskScriptId = _this.getError1(30); }
									}
									else if (['addQuestsScript', 'editQuestsScript', 'deleteQuestsScript'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError5(5); }
									}
								}
								else if (data.response.status == 409) {
									if (['addQuestsTask', 'editQuestsTask'].includes(_this.noxType)) {
										if      (data.response.data.error == 'SCRIPT_ID_NOT_WORKING') { _this.noxAlertQuestsTaskScriptId = _this.getError1(27); }
										else if (data.response.data.error == 'PRIORITY_DUPLICATE') { _this.noxAlertQuestsTaskPriority = _this.getError1(28); }
									}
									else if (['deleteQuestsScript'].includes(_this.noxType)) {
										if (data.response.data.error == 'SCRIPT_ID_ALREADY_SELECTED') { _this.noxAlert = _this.getError5(6); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError1(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsRewards: function(event) {
				this.noxQuestsTaskIsRewards = Number(event.value);
			},
			toggleChangeForIsActive: function(event) {
				this.noxQuestsTaskIsActive = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalAdminQuests');
			}
		}
	}
</script>
